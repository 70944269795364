import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Link from 'gatsby-link';

import styled from 'styled-components';

import Template from './../layouts/Template';

class PortIndex extends Component {
	render() {
		const siteTitle = this.props.data.site.siteMetadata.title;
		const { edges } = this.props.data.allMarkdownRemark;

		const BlogSection = styled.section`
			${tw`text-center pt-24`};

			header {
				${tw`mt-5`};
			}
			.title-big {
				${tw`sm:text-128`};
				top: -0.55em;
				margin-bottom: -0.35em;
			}
			.subtitle {
				${tw`font-sans text-24 sm:text-20 opacity-50`};
				text-transform: uppercase;
			}
			h2 {
				${tw`font-round font-bold text-secondary-darker tracking-56 text-36`};
				&:hover {
					${tw`font-bold`};
				}
			}
		`;

		const PostLink = styled.div`
			${tw`mb-8`};
		`;

		return (
			<Template title={`Portfolio | ${siteTitle}`} desc="Sophia Zey's Portfolio">
				<BlogSection>
					<div className="wrapper">
						<div className="container">
							<div className="box">
								<header className="title-big">
									<h1>Portfolio</h1>
								</header>

								{edges.map(({ node }) => (
									<PostLink key={node.fields.slug}>
										<Link to={node.fields.slug}>
											<h2>{node.frontmatter.title}</h2>
											<h5>{node.frontmatter.date}</h5>
										</Link>
									</PostLink>
								))}
							</div>
						</div>
					</div>
				</BlogSection>
			</Template>
		);
	}
}

export default PortIndex;

export const portfolioQuery = graphql`
	query portfolioQuery {
		site {
			siteMetadata {
				title
			}
		}
		allMarkdownRemark(
			filter: { frontmatter: { type: { eq: "portfolio" }, archive: { ne: true } } }
			sort: { fields: [frontmatter___date], order: DESC }
		) {
			edges {
				node {
					fields {
						slug
					}
					frontmatter {
						title
						date(formatString: "MMMM DD, YYYY")
					}
				}
			}
		}
	}
`;
